<template>
  <div class="info">
    <h3 class="title">患者信息列表</h3>
    <div class="card">
      <div class="top mb30">
        <div class="add-btn" @click="addInfo()">
          <img src="@/assets/img_08.png" alt="" class="img" />
          新增订单
        </div>
        <div class="input-box">
          <el-input v-model="search.name" placeholder="关键字"></el-input>
        </div>
        <el-select v-model="search.yy" placeholder="医院名称" filterable @change="changeYy" clearable>
          <el-option v-for="item in optionsYy" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="search.ys" filterable placeholder="医生名字" clearable>
          <el-option v-for="item in optionsYs" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="search.type" placeholder="产品类型" clearable>
          <el-option v-for="item in optionsType" :key="item.id" :label="item.title" :value="item.id">
          </el-option>
        </el-select>
        <div class="big-box">
          <el-date-picker v-model="search.time" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>

        <div class="small-box">
          <el-select v-model="search.state" placeholder="状态" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="search-btn" @click="getList">搜索</div>
      </div>
      <div class="table">
        <el-table :data="list" style="width: 100%" v-loading="loading" height="456">
          <el-table-column prop="order_sn" label="订单编号" width="80" align="center">
            <!-- <template slot-scope="scope">
              {{ scope.row.name }}
            </template> -->
          </el-table-column>
          <el-table-column prop="date" label="患者姓名" width="80" align="center">
            <template slot-scope="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="医院/医生" width="140" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.hospital">
                <p>{{ scope.row.hospital.title }}</p>
                <p v-if="scope.row.doctor">{{ scope.row.doctor.title }}</p>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="date"
            label="医生名字"
            width="90"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.doctor.title }}
            </template>
          </el-table-column> -->
          <el-table-column prop="date" label="产品类型/产品名称" width="140" align="center">
            <template slot-scope="scope">
              <div>
                <p v-if="scope.row.product">{{ scope.row.product.title }}</p>
                <p v-if="scope.row.ptitle">{{ scope.row.ptitle.title }}</p>
                <!-- <p
                  v-if="
                    scope.row.is_check_in == 1 || scope.row.is_check_in == 2
                  "
                >
                  {{ scope.row.amount }}
                </p> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="牙位" width="330" align="center">
            <template slot-scope="scope">
              <div class="y-wei">
                <div class="box" v-for="(item, index) in ywList" :key="index">
                  <div class="item" :class="{
                    active: isSelectTable(subItem.id, scope.row.tooth),
                  }" v-for="subItem in item" :key="subItem.id">
                    {{ subItem.lable }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="date"
            label="预计金额（元）"
            width="130"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.amount }}
            </template>
          </el-table-column> -->
          <el-table-column prop="date" label="已上传文件" width="110" align="center">
            <template slot-scope="scope">
              <div class="file-box" v-if="scope.row.file">
                <a :href="'http://heheoss.hanchengvip.com/' + item" class="file-item" target="downloadFile"
                  v-for="(item, index) in getarr(scope.row.file)" :key="index">
                  附件{{ index + 1 }}</a>
              </div>
              <div v-else>暂无附件</div>
            </template>
          </el-table-column>
          <el-table-column label="修改时间" width="100" align="center">
            <template slot-scope="scope">
              {{ scope.row.update_time }}
            </template>
          </el-table-column>
          <el-table-column prop="color" label="产品颜色" width="80" align="center">
          </el-table-column>
          <el-table-column prop="attribute" label="订单属性" width="80" align="center">
          </el-table-column>
          <el-table-column prop="date" label="状态" width="80" align="center">
            <template slot-scope="scope">
              <!-- <div v-if="scope.row.is_check_in" :style="{ color: '#F17317' }">
                已登记
              </div> -->
              <div :style="{ color: '#2D8CF0' }">
                {{ scope.row.is_check_in | nCheck }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" align="center">
            <template slot-scope="scope">
              <div @click="seeDetail(scope.row)" :style="{ cursor: 'pointer' }">
                详情
              </div>
              <!-- <div @click="editInfo(scope.row)" :style="{ cursor: 'pointer' }" v-if="scope.row.is_check_in === 3 || scope.row.is_check_in === 0
                ">
                编辑
              </div> -->
              <div v-if="scope.row.is_check_in === 0" @click="delInfo(scope.row.id)" :style="{ cursor: 'pointer' }">
                删除
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="pagination.currentPage" :page-sizes="pagination.sizes" :page-size.sync="pagination.size"
          layout="total, sizes, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="患者信息上传" :visible.sync="dialogFormVisible" width="700px" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="add-form" v-loading="typeNameLoading">
        <el-form :model="form" ref="form" :rules="rules" label-width="110px">
          <el-form-item label="产品类型" prop="type">
            <el-radio-group v-model="form.type" @change="getProductTitle">
              <el-radio :label="item.id" v-for="item in optionsType1" :key="item.id">{{ item.title }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品名称" prop="typeName" v-if="optionsTypeName && optionsTypeName.length">
            <el-radio-group v-model="form.typeName" @change="clearValidate">
              <el-radio :label="item.id" v-for="item in optionsTypeName" :key="item.id">{{ item.title }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="患者姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入患者姓名"></el-input>
          </el-form-item>
          <el-form-item label="医院名称" prop="yyName">
            <el-select v-model="form.yyName" filterable allow-create placeholder="请选择医院" @change="changeYy">
              <el-option v-for="item in optionsYy" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医生姓名" prop="ysName">
            <el-select v-model="form.ysName" filterable allow-create placeholder="请选择医生" @change="changeYs">
              <el-option v-for="item in optionsYs" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="产品类型" prop="type">
            <el-select
              v-model="form.type"
              placeholder="请选择产品类型"
              @change="changeType"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.id"
                :label="item.title"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="订单属性" prop="sx">
            <el-select v-model="form.sx" placeholder="请选择订单属性">
              <el-option
                v-for="item in optionsSX"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="订单属性" prop="sx">
            <el-radio-group v-model="form.sx">
              <el-radio :label="item.value" v-for="item in optionsSX" :key="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品颜色" label-width="110px" prop="color">
            <el-input v-model="form.color" placeholder="请输入产品颜色"></el-input>
          </el-form-item>
          <el-form-item label="设计要求" label-width="110px">
            <el-input v-model="form.yq" placeholder="请输入设计要求"></el-input>
          </el-form-item>
          <el-form-item label="随单附件" label-width="110px" prop="sdfj">
            <el-input v-model="form.sdfj" placeholder="请输入随单附件"></el-input>
          </el-form-item>
          <el-form-item label="选择牙位" prop="yw">
            <div class="y-wei">
              <div class="box" v-for="(item, index) in ywList" :key="index">
                <div class="item" :class="{ active: isSelect(subItem.id) }" v-for="subItem in item" :key="subItem.id"
                  @click="setYw(subItem.id)">
                  {{ subItem.lable }}
                </div>
              </div>
              <div class="select-top-all">
                <el-checkbox v-model="checkedTop" @change="selectTopAll">上颌</el-checkbox>
              </div>
              <div class="select-bottom-all">
                <el-checkbox v-model="checkedBottom" @change="selectTopBottom">下颌</el-checkbox>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="预计金额" label-width="110px">
            <el-input
              v-model="form.money"
              placeholder="预计金额（元）"
              disabled
            ></el-input>
          </el-form-item> -->

          <el-form-item label="上传附件" label-width="110px" prop="fj">
            <div class="fj-box">
              <div class="fj-item" v-for="(item, index) in form.fj" :key="index" @click="seeStf(item)">
                <img src="@/assets/img_06.jpg" alt="" class="del" @click.stop="delFj(index)" />
                附件{{ index + 1 }}
              </div>
              <el-upload class="avatar-uploader" action="" :show-file-list="false" :before-upload="upload"
                v-loading="uploadLoading">
                <div class="upload-btn">
                  <img src="@/assets/img_05.png" alt="" class="icon" />
                  设计单上传/比色照片/口扫文件/CT数据/X光片
                </div>
              </el-upload>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" v-loading="btnLoading || typeNameLoading" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogDesc" width="700px" :close-on-click-modal="false">
      <div class="add-form">
        <el-form :model="curDesc">
          <el-form-item label="患者姓名" label-width="110px">
            <el-input v-model="curDesc.name" placeholder="请输入患者姓名" disabled></el-input>
          </el-form-item>
          <el-form-item label="状态" label-width="110px" class="green">
            <el-select v-model="curDesc.is_check_in" placeholder="请选择状态" disabled>
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院名称" label-width="110px">
            <el-select v-model="curDesc.yyName" placeholder="请选择医院" disabled>
              <el-option v-for="item in optionsYy" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医生姓名" label-width="110px">
            <el-select v-model="curDesc.ysName" placeholder="请选择医生" disabled>
              <el-option v-for="item in optionsYs" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品类型" label-width="110px">
            <el-select v-model="curDesc.type" placeholder="请选择产品类型" disabled>
              <el-option v-for="item in optionsType" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品名称" label-width="110px">
            <el-select v-model="curDesc.typeName" placeholder="请选择产品名称" disabled>
              <el-option v-for="item in optionsTypeDescName" :key="item.id" :label="item.title" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单属性" prop="sx" label-width="110px">
            <el-select v-model="curDesc.attribute" placeholder="请选择订单属性" disabled>
              <el-option v-for="item in optionsSX" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品颜色" label-width="110px" prop="color">
            <el-input v-model="curDesc.color" placeholder="请输入产品颜色" disabled></el-input>
          </el-form-item>
          <el-form-item label="设计要求" label-width="110px" prop="yq">
            <el-input v-model="curDesc.design_req" placeholder="请输入设计要求" disabled></el-input>
          </el-form-item>
          <el-form-item label="随单附件" label-width="110px" prop="sdfj">
            <el-input v-model="curDesc.attached" placeholder="请输入随单附件" disabled></el-input>
          </el-form-item>
          <el-form-item label="选择牙位" label-width="110px">
            <div class="y-wei">
              <div class="box" v-for="(item, index) in ywList" :key="index">
                <div class="item" :class="{
                  active: isSelectTable(subItem.id, curDesc.yw),
                }" v-for="subItem in item" :key="subItem.id">
                  {{ subItem.lable }}
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="预计金额" label-width="110px" class="green">
            <el-input
              v-model="curDesc.money"
              placeholder="请输入金额（元）"
              disabled
            ></el-input>
          </el-form-item> -->
          <el-form-item label="上传附件" label-width="110px">
            <div class="fj-box" v-if="curDesc.fj.length">
              <div class="fj-item" v-for="(item, index) in curDesc.fj" :key="index" @click="seeStf(item)">
                附件{{ index + 1 }}
                <a :href="'http://heheoss.hanchengvip.com/' + item" @click.stop="" target="downloadFile"
                  class="fj-item-down">
                  下载
                </a>
              </div>
            </div>
            <div class="fj-list" v-else>
              <p class="empty">暂无附件</p>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import request from "@/common/js/request.js";
import moment from "moment";
export default {
  name: "info",
  data() {
    return {
      search: {
        name: "",
        yy: "",
        ys: "",
        type: "",
        time: "",
        state: "",
      },
      checkedTop: false,
      checkedBottom: false,
      optionsType1: [],
      optionsTypeName: [],
      typeNameLoading: false,
      uploadLoading: false,
      optionsTypeDescName: [],
      ywList: [
        [
          { id: "1", lable: "18" },
          { id: "2", lable: "17" },
          { id: "3", lable: "16" },
          { id: "4", lable: "15" },
          { id: "5", lable: "14" },
          { id: "6", lable: "13" },
          { id: "7", lable: "12" },
          { id: "8", lable: "11" },
        ],
        [
          { id: "9", lable: "21" },
          { id: "10", lable: "22" },
          { id: "11", lable: "23" },
          { id: "12", lable: "24" },
          { id: "13", lable: "25" },
          { id: "14", lable: "26" },
          { id: "15", lable: "27" },
          { id: "16", lable: "28" },
        ],
        [
          { id: "17", lable: "48" },
          { id: "18", lable: "47" },

          { id: "19", lable: "46" },
          { id: "20", lable: "45" },
          { id: "21", lable: "44" },
          { id: "22", lable: "43" },
          { id: "23", lable: "42" },
          { id: "24", lable: "41" },
        ],
        [
          { id: "25", lable: "31" },
          { id: "26", lable: "32" },
          { id: "27", lable: "33" },
          { id: "28", lable: "34" },
          { id: "29", lable: "35" },
          { id: "30", lable: "36" },
          { id: "31", lable: "37" },
          { id: "32", lable: "38" },
        ],
      ],
      form: {
        id: "",
        name: "",
        yyName: "",
        ysName: "",
        typeName: "",
        type: "",
        yw: [],
        money: "0/元",
        fj: [],
        sx: "",
        color: "",
        yq: "",
        sdfj: "",
      },
      dialogFormVisible: false,
      dialogDesc: false,
      loading: false,
      pagination: {
        sizes: [10, 30, 50, 100],
        size: 10,
        total: 0,
        currentPage: 1,
      },
      optionsSX: [
        {
          value: "加急",
          label: "加急",
        },
        {
          value: "试戴",
          label: "试戴",
        },
        {
          value: "返工",
          label: "返工",
        },
      ],
      options: [
        {
          value: "",
          label: "状态",
        },
        {
          value: 0,
          label: "已发送",
        },
        {
          value: 1,
          label: "已接收",
        },
        {
          value: 2,
          label: "已发货",
        },
        {
          value: 3,
          label: "已退回",
        },
      ],
      list: [],
      optionsYy: [],
      optionsType: [],
      optionsYs: [],
      // rules: {
      //   name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
      //   yyName: [{ required: true, message: "请选择医院", trigger: "change" }],
      //   ysName: [{ required: true, message: "请选择医生", trigger: "change" }],
      //   typeName: [{ required: true, message: "请选择产品名称", trigger: "change" }],
      //   type: [{ required: true, message: "请选择产品", trigger: "change" }],
      //   yw: [{ required: true, message: "请选择牙位", trigger: "change" }],
      //   fj: [{ required: true, message: "请上传附件", trigger: "change" }],
      //   // sx: [{ required: true, message: "请选择订单属性", trigger: "change" }],
      //   color: [{ required: true, message: "请输入产品颜色", trigger: "blur" }],
      //   yq: [{ required: true, message: "请输入设计要求", trigger: "blur" }],
      //   // sdfj: [{ required: true, message: "请输入随单附件", trigger: "blur" }],
      // },
      curDesc: {
        name: "",
        yyName: "",
        ysName: "",
        type: "",
        yw: "",
        money: "0/元",
        fj: [],
        is_check_in: 0,
        color: "",
        attribute: "",
        design_req: "",
        attached: "",
      },
      btnLoading: false
    };
  },
  computed: {
    rules() {
      if (this.form.typeName) {
        let obj = {
          yw: [{ required: true, message: "请选择牙位", trigger: "change" }],
          fj: [{ required: true, message: "请上传附件", trigger: "change" }],
          typeName: [
            { required: true, message: "请选择产品名称", trigger: "change" },
          ],
          type: [{ required: true, message: "请选择产品", trigger: "change" }],
        };
        const nameItem = this.optionsTypeName.find(
          (item) => item.id == this.form.typeName
        );
        if (!nameItem) {
          return obj;
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "患者姓名") != -1
        ) {
          obj.name = [
            { required: true, message: "请输入患者姓名", trigger: "blur" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "医院名称") != -1
        ) {
          obj.yyName = [
            { required: true, message: "请选择医院", trigger: "change" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "医生姓名") != -1
        ) {
          obj.ysName = [
            { required: true, message: "请选择医生", trigger: "change" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "产品颜色") != -1
        ) {
          obj.color = [
            { required: true, message: "请输入产品颜色", trigger: "blur" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "设计要求") != -1
        ) {
          obj.yq = [
            { required: true, message: "请输入设计要求", trigger: "blur" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "随单附件") != -1
        ) {
          obj.sdfj = [
            { required: true, message: "请输入随单附件", trigger: "blur" },
          ];
        }
        if (
          nameItem.must_choose_str &&
          nameItem.must_choose_str.findIndex((item) => item == "订单属性") != -1
        ) {
          obj.sx = [
            { required: true, message: "请选择订单属性", trigger: "change" },
          ];
        }
        return obj;
      }
      return {
        name: [{ required: true, message: "请输入患者姓名", trigger: "blur" }],
        yyName: [{ required: true, message: "请选择医院", trigger: "change" }],
        ysName: [{ required: true, message: "请选择医生", trigger: "change" }],
        typeName: [
          { required: true, message: "请选择产品名称", trigger: "change" },
        ],
        type: [{ required: true, message: "请选择产品", trigger: "change" }],
        yw: [{ required: true, message: "请选择牙位", trigger: "change" }],
        fj: [{ required: true, message: "请上传附件", trigger: "change" }],
        // sx: [{ required: true, message: "请选择订单属性", trigger: "change" }],
        color: [{ required: true, message: "请输入产品颜色", trigger: "blur" }],
        yq: [{ required: true, message: "请输入设计要求", trigger: "blur" }],
        // sdfj: [{ required: true, message: "请输入随单附件", trigger: "blur" }],
      };
    },
  },
  created() {
    this.getConfig();
    this.getList();
  },
  methods: {
    seeStf(val) {
      window.open("http://heheadmin.vhost189.datalink.cc/showStl/?info=" + "https://heheoss.hanchengvip.com/" + val)
    },
    selectTopAll(val) {
      if (val) {
        for (let id = 1; id <= 16; id++) {
          let index = this.form.yw.findIndex((item) => item == id);
          if (index == -1) {
            this.form.yw.push(id);
          }
          if (this.form.type && id == 16) {
            let shop = this.optionsType.find(
              (item) => item.id == this.form.type
            );
            this.form.money =
              (shop.price * this.form.yw.length).toFixed(2) + "/元";
          }
        }
      } else {
        for (let id = 1; id <= 16; id++) {
          let index = this.form.yw.findIndex((item) => item == id);
          if (index != -1) {
            this.form.yw.splice(index, 1);
          }
          if (this.form.type && id == 16) {
            let shop = this.optionsType.find(
              (item) => item.id == this.form.type
            );
            this.form.money =
              (shop.price * this.form.yw.length).toFixed(2) + "/元";
          }
        }
      }
    },
    selectTopBottom(val) {
      if (val) {
        for (let id = 17; id <= 32; id++) {
          let index = this.form.yw.findIndex((item) => item == id);
          if (index == -1) {
            this.form.yw.push(id);
          }
          if (this.form.type && id == 32) {
            let shop = this.optionsType.find(
              (item) => item.id == this.form.type
            );
            this.form.money =
              (shop.price * this.form.yw.length).toFixed(2) + "/元";
          }
        }
      } else {
        for (let id = 17; id <= 32; id++) {
          let index = this.form.yw.findIndex((item) => item == id);
          if (index != -1) {
            this.form.yw.splice(index, 1);
          }
          if (this.form.type && id == 32) {
            let shop = this.optionsType.find(
              (item) => item.id == this.form.type
            );
            this.form.money =
              (shop.price * this.form.yw.length).toFixed(2) + "/元";
          }
        }
      }
    },
    addInfo() {
      this.form = {
        id: "",
        name: "",
        yyName: "",
        ysName: "",
        typeName: "",
        type: this.optionsType1[0].id,
        yw: [],
        money: "0/元",
        fj: [],
      };
      this.btnLoading = false
      this.dialogFormVisible = true;
      this.getProductTitle();
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
      });
    },
    getProductTitle(isSet = true) {
      // return
      if (!this.form.type) {
        return;
      }
      this.typeNameLoading = true;
      // this.$nextTick(() => {
      //   this.$refs["form"].clearValidate();
      // });
      this.optionsTypeName = [];
      let oldId = this.form.typeName;
      this.form.typeName = "";
      this.$api
        .product_title_lists({
          product_main_id: this.form.type,
        })
        .then((res) => {
          if (res.code == 200) {
            this.typeNameLoading = false;
          }
          this.optionsTypeName = res.data;
          if (!isSet) {
            this.form.typeName = oldId
            return;
          }
          if (res.data && res.data[0]) {
            this.form.typeName = res.data[0].id;
          } else {
            this.form.typeName = "";
          }
          this.$nextTick(() => {
            this.$refs["form"].clearValidate();
          });
          // this.form.typeName = res.data
        });
    },
    delInfo(id) {
      this.$confirm("是否确定患者信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api
            .patient_delete({
              patient_id: id,
            })
            .then((res) => {
              if (res.code == 200) {
                this.getList();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                  showClose: true,
                  duration: 1000,
                });
              } else {
                this.$message({
                  showClose: true,
                  message: res.msg,
                  type: "error",
                  duration: 1000,
                });
              }
            });
        })
        .catch(() => { });
    },
    editInfo(item) {
      console.log(item);
      this.form = {
        name: item.name,
        yyName: item.hospital_id,
        ysName: item.doctor_id,
        type: item.product_id,
        typeName: item.product_title_id,
        yw: this.getarr(item.tooth),
        money: item.amount + "/元",
        fj: this.getarr(item.file),
        id: item.id,
        sx: item.attribute,
        color: item.color,
        yq: item.design_req,
        sdfj: item.attached,
      };
      this.btnLoading = false
      this.dialogFormVisible = true;
      this.getProductTitle(false);
    },
    delFj(index) {
      this.form.fj.splice(index, 1);
    },
    upload(file) {
      this.uploadLoading = true;
      this.$api
        .qiniu_token()
        .then((res) => {
          const token = res.data.token;
          let form = new FormData();
          form.append("token", token);
          form.append("file", file);
          form.append(
            "key",
            uuidv4() + file.name.substring(file.name.lastIndexOf("."))
          );
          request({
            url: "https://up-z2.qiniup.com",
            method: "post",
            data: form,
          })
            .then((res) => {
              this.uploadLoading = false;
              this.form.fj.push(res.key);
            })
            .catch((e) => {
              console.log(e);
              this.uploadLoading = false;
            });
        })
        .catch((e) => {
          this.uploadLoading = false;
        });
      return false;
    },
    seeDetail(item) {
      console.log(item)
      this.curDesc = {
        name: item.name,
        yyName: item.hospital_id ? item.hospital_id : '',
        ysName: item.doctor ? item.doctor.title : '',
        type: item.product_id,
        typeName: item.product_title_id,
        yw: item.tooth,
        money: item.amount + "/元",
        fj: item.file ? item.file.split(",") : [],
        is_check_in: this.nmCheck(item.is_check_in),
        color: item.color,
        attribute: item.attribute,
        design_req: item.design_req,
        attached: item.attached,
      };
      this.$api
        .product_title_lists({
          product_main_id: this.curDesc.type,
        })
        .then((res) => {
          this.optionsTypeDescName = res.data;
          this.dialogDesc = true;
        });
    },
    getList() {
      this.loading = true;
      let create_time = "";
      if (this.search.time && this.search.time.length) {
        create_time =
          create_time +
          moment(this.search.time[0]).format("YYYY-MM-DD") +
          " 00:00:00|";
        create_time =
          create_time +
          moment(this.search.time[1]).format("YYYY-MM-DD") +
          " 23:59:59";
      }
      this.$api
        .patient_lists({
          page: this.pagination.currentPage,
          limit: this.pagination.size,
          key: this.search.name,
          hospital_id: this.search.yy,
          product_id: this.search.type,
          doctor_id: this.search.ys,
          is_check: this.search.state,
          create_time,
        })
        .then((res) => {
          this.loading = false;
          this.pagination.total = res.data.count_all;
          this.list = res.data.lists;
        });
    },
    getarr(file) {
      if (file) {
        return file.split(",");
      }
      return [];
    },
    handleSizeChange() {
      this.getList();
    },
    handleCurrentChange() {
      this.getList();
    },
    getConfig() {
      this.$api.product_lists().then((res) => {
        if (res.data.length) {
          this.optionsType.push({
            title: "产品类型",
            id: "",
          });
        }
        this.optionsType.push(...res.data);
        this.optionsType1 = res.data;
      });
      this.$api.hospital_lists().then((res) => {
        if (res.data) {
          if (res.data.length) {
            this.optionsYy.push({
              title: "医院名称",
              id: "",
            });
          }
          this.optionsYy.push(...res.data);
        } else {
          this.optionsYy.push({
            title: "医院名称",
            id: "",
          });
        }
      });
    },
    setYw(id) {
      let index = this.form.yw.findIndex((item) => item == id);
      if (index == -1) {
        this.form.yw.push(id);
      } else {
        this.form.yw.splice(index, 1);
      }
      if (this.form.type) {
        let shop = this.optionsType.find((item) => item.id == this.form.type);
        this.form.money = (shop.price * this.form.yw.length).toFixed(2) + "/元";
      }
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (!this.form.typeName) {
            this.$message({
              showClose: true,
              message: "该产品类型暂无产品名称！",
              type: "error",
              duration: 1000,
            });
            return false;
          }
          this.btnLoading = true
          if (this.form.id) {
            this.$api
              .patient_update({
                hospital_id: this.form.yyName,
                doctor_id: this.form.ysName,
                product_id: this.form.type,
                product_title_id: this.form.typeName,
                name: this.form.name,
                tooth: this.form.yw.join(),
                file: this.form.fj.join(),
                patient_id: this.form.id,
                color: this.form.color,
                attribute: this.form.sx,
                design_req: this.form.yq,
                attached: this.form.sdfj,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    type: "success",
                    message: res.msg,
                    showClose: true,
                    duration: 1000,
                  });
                  this.form = {
                    name: "",
                    yyName: "",
                    ysName: "",
                    typeName: "",
                    type: "",
                    yw: [],
                    money: "0/元",
                    fj: [],
                    sx: "",
                    color: "",
                    yq: "",
                    sdfj: "",
                  };
                  this.$refs["form"].resetFields();
                  this.getList();
                  this.btnLoading = false
                  this.dialogFormVisible = false;
                } else {
                  this.btnLoading = false
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                }
              });
          } else {
            this.$api
              .patient_create({
                hospital_id: this.form.yyName,
                doctor_id: this.form.ysName,
                product_id: this.form.type,
                product_title_id: this.form.typeName,
                name: this.form.name,
                tooth: this.form.yw.join(),
                file: this.form.fj.join(),
                color: this.form.color,
                attribute: this.form.sx,
                design_req: this.form.yq,
                attached: this.form.sdfj,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "success",
                    duration: 1000,
                  });

                  this.form = {
                    name: "",
                    yyName: "",
                    ysName: "",
                    type: "",
                    yw: [],
                    money: "0/元",
                    fj: [],
                    sx: "",
                    color: "",
                    yq: "",
                    sdfj: "",
                  };
                  this.$refs["form"].resetFields();
                  this.getList();
                  this.btnLoading = false
                  this.dialogFormVisible = false;
                } else {
                  this.$message({
                    showClose: true,
                    message: res.msg,
                    type: "error",
                    duration: 1000,
                  });
                  this.btnLoading = false
                }
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    changeType() {
      let shop = this.optionsType.find((item) => item.id == this.form.type);
      this.form.money = (shop.price * this.form.yw.length).toFixed(2) + "/元";
    },
    isSelect(id) {
      let index = this.form.yw.findIndex((item) => item == id);
      if (index == -1) {
        return false;
      } else {
        return true;
      }
    },
    isSelectTable(id, arrStr) {
      if (!arrStr) {
        return false;
      }
      let arr = arrStr.split(",");
      let index = arr.findIndex((item) => item == id);
      if (index == -1) {
        return false;
      } else {
        return true;
      }
    },
    changeYy(id) {
      if (id) {
        if (this.optionsYy.findIndex(item => item.id == id) != -1) {
          this.search.ys = "";
          this.form.ysName = "";
          this.$api.doctor_lists({ hospital_id: id }).then((res) => {
            this.optionsYs = [];
            if (res.data && res.data.length) {
              this.optionsYs.push({
                title: "医生名称",
                id: "",
              });
            }
            if (res.data) {
              this.optionsYs.push(...res.data);
            }
          });
        } else {
          this.$confirm('是否创建医院?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api
              .hospital_create({
                title: id,
                desc: "",
              }).then(resH => {
                this.$api.hospital_lists().then((res) => {
                  this.optionsYy.length = 0
                  if (res.data) {
                    if (res.data.length) {
                      this.optionsYy.push({
                        title: "医院名称",
                        id: "",
                      });
                    }
                    this.optionsYy.push(...res.data);
                  } else {
                    this.optionsYy.push({
                      title: "医院名称",
                      id: "",
                    });
                  }
                  this.$nextTick(() => {
                    this.form.yyName = resH.data.id * 1
                  })
                  this.search.ys = "";
                  this.form.ysName = "";
                  this.optionsYs = [];
                  this.optionsYs.push({
                    title: "医生名称",
                    id: "",
                  });
                });
              })
          }).catch(() => {
            this.form.yyName = ""
            this.search.ys = "";
            this.form.ysName = "";
          })

        }
      }
    },
    changeYs(id) {
      if (id && this.form.yyName) {
        if (this.optionsYs.findIndex(item => item.id == id) == -1) {
          this.search.ys = "";
          this.form.ysName = "";
          this.$confirm('是否创建医生?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$api
              .doctor_create({
                hospital_id: this.form.yyName,
                title: id,
                desc: "",
              }).then(resS => {
                this.$api.doctor_lists({ hospital_id: this.form.yyName }).then((res) => {
                  this.optionsYs = [];
                  if (res.data && res.data.length) {
                    this.optionsYs.push({
                      title: "医生名称",
                      id: "",
                    });
                  }
                  if (res.data) {
                    this.optionsYs.push(...res.data);
                  }
                  this.$nextTick(() => {
                    this.form.ysName = resS.data.id * 1
                  })
                });
              })
          }).catch(() => {
          });

        }
      } else {
        this.search.ys = "";
        this.form.ysName = "";
      }
    },
    handleClose(done) {
      this.form = {
        name: "",
        yyName: "",
        ysName: "",
        type: "",
        typeName: "",
        yw: [],
        money: "0/元",
        fj: [],
      };
      this.checkedTop = false;
      this.checkedBottom = false;
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
      done();
    },
    nmCheck(val) {
      switch (val) {
        case 0:
          return "已发送";
        case 1:
          return "已接收";
        case 2:
          return "已发货";
        case 3:
          return "已退回";
        default:
          return val;
      }
    },
  },
  filters: {
    nCheck(val) {
      switch (val) {
        case 0:
          return "已发送";
        case 1:
          return "已接收";
        case 2:
          return "已发货";
        case 3:
          return "已退回";
        default:
          return val;
      }
    },
  },
};
</script>

<style lang="scss">
.green {
  .el-input__inner {
    color: #2d8cf0 !important;
  }
}

.info {
  .card {
    .input-box {
      .el-input__inner {
        width: 130px;
        margin-right: 10px;
      }

      .el-input {
        width: unset;
      }
    }

    .top {
      .el-input__inner {
        width: 170px;
        margin-right: 10px;
      }

      .el-input__suffix {
        right: 10px;
      }
    }

    .big-box {
      .el-input__inner {
        width: 280px;
        margin-right: 10px;
      }

      .el-date-editor .el-range-separator {
        padding: 0;
      }
    }

    .small-box {
      .el-input__inner {
        width: 130px;
        margin-right: 10px;
      }
    }

    .bottom {
      .el-pager {
        .number {
          border: 1px solid #ebebeb;
          margin-right: 4px;

          &:hover {
            color: #2d8cf0;
          }

          &.active {
            background: #2d8cf0;
            border: 1px solid #2d8cf0;
            color: #ffffff;
          }
        }
      }
    }

    .el-table th {
      background: #eef4f1;
      color: #333333;
    }
  }

  .add-form {
    .el-form-item {
      margin-bottom: 22px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .el-form-item--medium .el-form-item__content {
      margin-left: 0 !important;
    }

    .el-input__inner {
      width: 420px;
    }

    .fj-box {
      display: flex;
      flex-wrap: wrap;

      .fj-item {
        line-height: 110px;
        margin-right: 15px;
        padding: 0 10px;
        position: relative;
        cursor: pointer;

        .del {
          position: absolute;
          width: 26px;
          height: 16px;
          top: 30px;
          right: -10px;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }

    .upload-btn {
      width: 110px;
      height: 110px;
      background: #f1f9f5;
      border: 1px dashed #9a9c9b;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1.3;

      .icon {
        width: 26px;
        height: 26px;
        margin-bottom: 12px;
      }
    }

    .y-wei {
      width: 420px;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .box {
        display: flex;
        padding-right: 8px;
        border-bottom: 1px dashed #979797;
        border-right: 1px dashed #979797;
        padding-bottom: 10px;

        &:nth-child(2n) {
          padding-right: 0;
          border-right: none;
          padding-left: 10px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          border-bottom: none;
          padding-bottom: 0;
          padding-top: 10px;
        }

        .item {
          width: 23px;
          line-height: 27px;
          background: #eef4f1;
          border-radius: 5px;
          margin-right: 2px;
          cursor: pointer;
          text-align: center;
          font-size: 16px;
          color: #333333;

          &.active {
            background: #2d8cf0;
            color: #fff;
          }
        }
      }

      .select-top-all {
        position: absolute;
        right: -60px;
        top: -2px;
        color: #333;
      }

      .select-bottom-all {
        position: absolute;
        right: -60px;
        bottom: -2px;
        color: #333;
      }
    }
  }

  .el-button--primary {
    background: #2d8cf0 !important;
    border-color: #2d8cf0 !important;
  }
}
</style>
<style lang="scss" scoped>
.add-form {
  ::v-deep .el-radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.info {
  .title {
    font-size: 30px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 24px;
  }

  .card {
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 30px 30px 40px;
    position: relative;
    z-index: 1;

    .top {
      &.mb30 {
        margin-bottom: 20px;
      }

      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .search-btn {
        width: 90px;
        line-height: 36px;
        background: #2d8cf0;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        // margin-right: 30px;
        cursor: pointer;
      }

      .add-btn {
        width: 126px;
        height: 36px;
        background: #2d8bf010;
        border: 1px solid #2d8cf0;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #2d8cf0;
        cursor: pointer;
        margin-right: 10px;

        .img {
          margin-right: 6px;
        }
      }
    }

    .table {
      margin-bottom: 35px;

      .y-wei {
        width: 320px;
        display: flex;
        flex-wrap: wrap;

        .box {
          display: flex;
          padding-right: 8px;
          border-bottom: 1px dashed #979797;
          border-right: 1px dashed #979797;
          padding-bottom: 10px;
          width: 160px;
          height: 32px;

          &:nth-child(2n) {
            padding-right: 0;
            border-right: none;
            padding-left: 10px;
          }

          &:nth-child(2n + 1) {
            justify-content: flex-end;
          }

          &:nth-child(3),
          &:nth-child(4) {
            border-bottom: none;
            padding-bottom: 0;
            padding-top: 10px;
          }

          .item {
            display: none;
            width: 16px;
            line-height: 20px;
            // background: #eef4f1;
            border-radius: 5px;
            margin-right: 2px;
            cursor: pointer;
            text-align: center;
            font-size: 14px;
            color: #333333;

            &.active {
              display: block;
              // background: #2D8CF0;
              // color: #fff;
            }
          }
        }
      }

      .file-box {
        display: flex;
        flex-wrap: wrap;

        .file-item {
          line-height: 1.5;
          margin-right: 8px;
        }
      }
    }

    .bottom {
      display: flex;
      justify-content: center;
    }
  }
}
</style>